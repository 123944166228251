<template>
  <section>
    <v-form ref="form" v-model="valid" lazy-validation class="form-section">
      <v-row>
        <v-col md="12" class="mb-4">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-select
            :items="pages"
            v-model="page"
            label="Page"
            outlined
            item-text="text"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col md="8">
          <v-text-field v-model="title" label="Title" outlined></v-text-field>
        </v-col>
        <v-col md="8">
          <v-textarea
            v-model="description"
            label="Descrition"
            outlined
          ></v-textarea>
        </v-col>
        <v-col md="8">
          <v-text-field
            v-model="keywords"
            label="Keywords"
            outlined
          ></v-text-field>
        </v-col>
        <v-col md="12">
          <v-btn
            @click="validateSubmit"
            :loading="submitLoading"
            class="primary"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="3000">{{ message }}</v-snackbar>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/dashboard/PageHeader";
export default {
  name: "Meta",
  components: { PageHeader },
  data: () => {
    return {
      headercontent: {
        title: "Add Meta",
      },
      valid: true,
      loading: true,
      submitLoading: false,
      page: "",
      pages: [
        {
          text: "Home",
          value: "home",
        },
        {
          text: "About",
          value: "about",
        },
        {
          text: "Contact",
          value: "contact",
        },
        {
          text: "Privacy Policy",
          value: "privacy-policy",
        },
        {
          text: "Terms and Conditions",
          value: "terms-and-conditions",
        },
        {
          text: "Find Jobs",
          value: "find-jobs",
        },
        {
          text:"Career Tips",
          value:"career-tips"
        },
        {
          text:"Education",
          value:"education"
        },
        {
          text:"Information",
          value:"information"
        },
        {
          text:"Business Tips",
          value:"business-tips"
        },
        {
          text:"Post A Job",
          value:"postajob"
        },
        {
          text:"Training",
          value:"training"
        },
        {
          text:"Category",
          value:"category"
        },
        {
          text:"All Courses",
          value:"allcourses"
        },
      ],
      snackbar: false,
      message: "Meta added successfully",
      title: "",
      description: "",
      keywords: "",
    };
  },
  methods: {
    ...mapActions(["addMeta", "getSingleMeta"]),
    async validateSubmit() {
      if (this.$refs.form.validate()) {
        this.submitLoading = true;
        await this.addMeta({
          page: this.page,
          title: this.title,
          description: this.description,
          keywords: this.keywords,
          _id: this.$route.params.id,
        });
        this.submitLoading = false;
        this.snackbar = true;
        this.message = "Meta added successfully";
        this.$router.push("/dashboard/meta");
      }
    },
  },
  computed: {
    ...mapGetters(["allMeta"]),
  },
  async created() {
    if (this.$route.params.id) {
      this.loading = true;
      await this.getSingleMeta(this.$route.params.id);
      this.page = this.allMeta.page;
      this.title = this.allMeta.title;
      this.description = this.allMeta.description;
      this.keywords = this.allMeta.keywords;
      this.headercontent.title = "Edit Meta";
      this.message = "Meta updated successfully";
    }
    this.loading = false;
  },
};
</script>

<style>
</style>